import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
  height: '100%',
  backgroundColor: '#f0f0f0',
});

export const FormContainer = styled.div({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  top: '50%',
  left: '50%',
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#fff',
  borderRadius: '2px',
  padding: '15px',
  margin: 'auto',
});

export const Logo = styled.div({
  display: 'flex',
  justifyContent: 'center',
});

export const MainForm = styled.div({
  marginTop: '50px',
  display: 'flex',
  flexDirection: 'column',
  width: '380px',
});

export const field = css`
  color: #000000 !important;
  font-size: 14px !important;
  font-weight: normal !important;

  span {
    color: red;
  }
`;

export const button = css`
  background-color: #1258af !important;
  width: 100%;
  color: white !important;
  font-weight: 500;
  border-radius: 2px !important;
`;
