import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { RootState } from '../../store/store';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 2 });

export const reportApi = createApi({
  reducerPath: 'reportApi',
  baseQuery: baseQueryWithRetry,
  tagTypes: ['Report'],
  endpoints: (builder) => ({
    getReport: builder.query<any, { periodType: string }>({
      query: ({ periodType }) => {
        return {
          url: `report/customer?periodType=${periodType}`,
          method: 'GET',
        };
      },
      providesTags: ['Report'],
    }),
  }),
});

export const { useGetReportQuery } = reportApi;
