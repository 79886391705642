import { useContext, createContext, useState, FC } from 'react';

type IToggleMenuContext = {
  isToggle: boolean;
  setToggle?: (isToggle: boolean) => void;
};

export const ToggleMenuContext = createContext<IToggleMenuContext>({
  isToggle: false,
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps {}

export const ToggleMenuProvider: FC<IProps> = ({ children }) => {
  const [isToggle, setToggle] = useState<boolean>(false);
  return (
    <ToggleMenuContext.Provider
      value={{
        isToggle,
        setToggle: (key: boolean) => setToggle(key),
      }}>
      {children}
    </ToggleMenuContext.Provider>
  );
};

export const useToggleMenu = () => useContext(ToggleMenuContext);
