import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { RootState } from '../../store/store';
import { queryParams } from '../../utils/query-string';
import { IPermissions } from './permissions';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 2 });

export const permissionApi = createApi({
  reducerPath: 'permissionApi',
  baseQuery: baseQueryWithRetry,
  tagTypes: ['Permission'],
  endpoints: (builder) => ({
    getPermissions: builder.query<{ data: IPermissions[] }, any>({
      query: () => {
        return {
          url: `permissions`,
          method: 'GET',
        };
      },
    }),
    getPermissionsByUnit: builder.query<any, { unitId: string }>({
      query: ({ unitId }) => {
        const params = {} as any;
        if (unitId) params.unitId = unitId;
        return {
          url: `permissions/byUnit?${queryParams(params)}`,
          method: 'GET',
        };
      },
      providesTags: ['Permission'],
    }),
    addPermission: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: 'permissions',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Permission'],
    }),
  }),
});

export const { useGetPermissionsQuery, useGetPermissionsByUnitQuery, useAddPermissionMutation } =
  permissionApi;
