import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { IUser } from './auth.type';
import { authApi } from './auth.service';
import { RootState } from '../../store/store';
import * as storage from '../../utils/storage';

const SESSION_STORAGE_KEY = 'user-info';

const isFetchBaseQueryErrorType = (errors: any) => {
  return errors?.data?.message;
};

export const storedUser = storage.getItem(SESSION_STORAGE_KEY) as null | {
  user: null | IUser;
  token: string | null;
  isAuthenticated: boolean;
};

const initialState =
  storedUser ||
  ({
    user: null,
    token: null,
    isAuthenticated: false,
  } as { user: null | IUser; token: string | null; isAuthenticated: boolean });

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.isAuthenticated = false;
      storage.clearItem(SESSION_STORAGE_KEY);
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApi.endpoints.login.matchPending, (state, action) => {
        console.log('pending', action);
      })
      .addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
        console.log('fulfilled', action);
        state.user = action?.payload?.data?.user;
        state.token = action?.payload?.data?.token;
        state.isAuthenticated = true;
        storage.saveItem(SESSION_STORAGE_KEY, state);
      })
      .addMatcher(authApi.endpoints.login.matchRejected, (state, action) => {
        console.log('rejected', action);
        toast.error(`${isFetchBaseQueryErrorType(action?.payload)}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  },
});

export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const { logout } = slice.actions;
export default slice.reducer;
