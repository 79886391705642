import { Popup, Image } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { logout } from '../../../modules/Auth/auth.slice';
import css from './Profile.module.scss';
import icArrow from '../icons/ic_arrow.svg';
import nonAvatar from '../icons/none_avatar.png';

const popupStyle = {
  padding: '0px 0 0 0',
  maxWidth: 150,
  minWidth: 150,
  borderRadius: '2px !important',
};

const ProfileMenu = () => {
  const dispatch = useDispatch();
  const profileMenuTrigger = (
    <div className={css.container}>
      <div className={css.profile}>
        <Image src={nonAvatar} circular size="mini" />
        <div className={css.information}>
          <span>Admin</span>
          <strong>Hoang Duc Trung</strong>
        </div>
      </div>
      <img src={icArrow} width={6} height={6} />
    </div>
  );
  return (
    <Popup
      flowing
      basic
      hoverable
      position="bottom right"
      size="huge"
      style={popupStyle}
      trigger={profileMenuTrigger}>
      <div className={css.menu} onClick={() => dispatch(logout())}>
        <div className={css.item}>Đăng xuất</div>
      </div>
    </Popup>
  );
};
export default ProfileMenu;
