import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { RootState } from '../../store/store';
import { queryParams } from '../../utils/query-string';
import { IListResponse } from '../../configs/constants';
import { IParams, ICourses } from './courses';
import { convertModelToFormData } from '../../utils/utility';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 2 });

export const courseApi = createApi({
  reducerPath: 'courseApi',
  baseQuery: baseQueryWithRetry,
  tagTypes: ['Course'],
  endpoints: (builder) => ({
    getCourses: builder.query<
      IListResponse<ICourses>,
      { page: number; pageSize: number; status?: string }
    >({
      query: ({ page, pageSize, status }) => {
        const params: IParams = {
          take: pageSize,
          skip: (page - 1) * pageSize,
        };
        if (status) params.status = status;
        return {
          url: `courses?${queryParams(params)}`,
          method: 'GET',
        };
      },
      providesTags: ['Course'],
    }),
    addCourse: builder.mutation<{ data: ICourses; type: string }, any>({
      query: (body) => {
        return {
          url: 'courses',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Course'],
    }),
    updateCourse: builder.mutation<{ data: ICourses; type: string }, any>({
      query: (body) => {
        const { id } = body;
        delete body.id;
        return {
          url: `courses/${id}`,
          method: 'PATCH',
          body: convertModelToFormData(body),
        };
      },
      invalidatesTags: ['Course'],
    }),
    deleteCourse: builder.mutation<{ data: string; type: string }, any>({
      query: (id) => {
        return {
          url: `courses/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Course'],
    }),
  }),
});

export const {
  useGetCoursesQuery,
  useAddCourseMutation,
  useUpdateCourseMutation,
  useDeleteCourseMutation,
} = courseApi;
