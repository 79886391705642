import { toast } from 'react-toastify';
import { isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { logout } from '../modules/Auth/auth.slice';

export const rtkQueryErrorLogger: Middleware = (store: MiddlewareAPI) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    if (action?.payload?.data?.code == 401) {
      store.dispatch(logout());
      // storage.clearItem(SESSION_STORAGE_KEY);
    } else {
      toast.error(`${action?.payload?.data?.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  return next(action);
};
