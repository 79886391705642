import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import logger from 'redux-logger';
import { rtkQueryErrorLogger } from '../middleware/error-handling';
import auth from '../modules/Auth/auth.slice';
import { authApi } from '../modules/Auth/auth.service';
import { userApi } from '../modules/Users/user.service';
import { challengeApi } from '../modules/Challenges/challenges.service';
import { newApi } from '../modules/News/news.service';
import { courseApi } from '../modules/Courses/courses.service';
import { tagsApi } from '../components/TagsDropdown/tags.service';
import { productApi } from '../modules/Products/products.service';
import { unitApi } from '../modules/Units/units.service';
import { unitDropdownApi } from '../components/UnitsDropdown/unitsDropdown.service';
import { permissionApi } from '../modules/Permissions/permissions.service';
import { adminApi } from '../modules/Admins/admins.service';
import { adsApi } from '../modules/Advertisements/ads.service';
import { notificationApi } from '../modules/Notifications/notifications.service';
import { resourceApi } from '../modules/Resources/resources.service';
import { resourceDropdownApi } from '../components/ResourcesDropdown/resourcesDropdown.service';
import { partnerApi } from '../modules/Partners/partners.service';
import { historyApi } from '../modules/Histories/histories.service';
import { reportApi } from '../modules/Reports/reports.service';

export const createStore = (options?: ConfigureStoreOptions['preloadedState'] | undefined) =>
  configureStore({
    reducer: {
      auth,
      [authApi.reducerPath]: authApi.reducer,
      [userApi.reducerPath]: userApi.reducer,
      [challengeApi.reducerPath]: challengeApi.reducer,
      [newApi.reducerPath]: newApi.reducer,
      [courseApi.reducerPath]: courseApi.reducer,
      [tagsApi.reducerPath]: tagsApi.reducer,
      [productApi.reducerPath]: productApi.reducer,
      [unitApi.reducerPath]: unitApi.reducer,
      [unitDropdownApi.reducerPath]: unitDropdownApi.reducer,
      [permissionApi.reducerPath]: permissionApi.reducer,
      [adminApi.reducerPath]: adminApi.reducer,
      [adsApi.reducerPath]: adsApi.reducer,
      [notificationApi.reducerPath]: notificationApi.reducer,
      [resourceApi.reducerPath]: resourceApi.reducer,
      [resourceDropdownApi.reducerPath]: resourceDropdownApi.reducer,
      [partnerApi.reducerPath]: partnerApi.reducer,
      [historyApi.reducerPath]: historyApi.reducer,
      [reportApi.reducerPath]: reportApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([
        authApi.middleware,
        userApi.middleware,
        challengeApi.middleware,
        newApi.middleware,
        courseApi.middleware,
        tagsApi.middleware,
        productApi.middleware,
        unitApi.middleware,
        unitDropdownApi.middleware,
        permissionApi.middleware,
        adminApi.middleware,
        adsApi.middleware,
        notificationApi.middleware,
        resourceApi.middleware,
        resourceDropdownApi.middleware,
        rtkQueryErrorLogger,
        logger,
        partnerApi.middleware,
        historyApi.middleware,
        reportApi.middleware,
      ]),
    ...options,
  });

export const store = createStore();

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
