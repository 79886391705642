/* eslint-disable @typescript-eslint/no-explicit-any */
/** @jsxImportSource @emotion/react */
import { Form, Input, Button, Message } from 'semantic-ui-react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useLoginMutation } from '../auth.service';
import { selectIsAuthenticated } from '../auth.slice';
import { field, button } from '../SignIn/styles.css';

const SignInSchema = Yup.object().shape({
  email: Yup.string().required('Email là bắt buộc').email('Email không đúng định dạng'),
  password: Yup.string().required('Mật khẩu là bắt buộc'),
});

const SignInForm = (): JSX.Element => {
  const [login, { error, isError, isLoading }] = useLoginMutation();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const onSubmit = (values: { email: string; password: string }) => {
    login(values);
  };

  const isFetchBaseQueryErrorType = (errors: any) => {
    return errors?.data?.message;
  };

  if (isAuthenticated) {
    return <Navigate to="/dashboard" replace />;
  }
  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={SignInSchema}
      onSubmit={onSubmit}>
      {(props) => {
        const { values, setFieldValue, errors, submitForm, setSubmitting } = props;
        return (
          <Form>
            {isError && error && (
              <Message negative>
                <p>{isFetchBaseQueryErrorType(error)}</p>
              </Message>
            )}
            <Form.Field>
              <label css={field}>
                Email của bạn<span> *</span>
              </label>
              <Input
                type="email"
                name="email"
                value={values.email}
                onChange={(_, data) => {
                  setFieldValue('email', data.value);
                  setSubmitting(false);
                }}
                error={!!errors.email}
              />
              {errors.email && <span className="errors">{errors.email}</span>}
            </Form.Field>

            <Form.Field>
              <label css={field}>
                Mật khẩu<span> *</span>
              </label>
              <Input
                type="password"
                name="password"
                onChange={(_, data) => {
                  setFieldValue('password', data.value);
                  setSubmitting(false);
                }}
                error={!!errors.password}
              />
              {errors.password && <span className="errors">{errors.password}</span>}
            </Form.Field>
            <Button
              as="a"
              css={button}
              onClick={submitForm}
              disabled={isLoading}
              loading={isLoading}>
              {' '}
              ĐĂNG NHẬP
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};
export default SignInForm;
