import { useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import css from './leftMenu.module.scss';
import logo from './icons/logo.png';
import arrowUp from './icons/caret_down.svg';
import { useToggleMenu } from '../../hooks/useToggleMenu';

const menu = [
  // {
  //   display: true,
  //   color: '',
  //   to: '',
  //   label: 'Báo cáo thu chi',
  //   icon: 'ic_dashboard',
  //   objectType: '',
  //   overviewType: '',
  // },
  {
    display: true,
    color: '',
    to: '',
    label: 'Khách hàng',
    icon: 'ic_customer',
    objectType: '',
    overviewType: '',
    isSubMenu: true,
    subMenu: [
      {
        display: true,
        color: '',
        to: 'users',
        label: 'Danh sách khách hàng',
        objectType: '',
        overviewType: '',
      },
      {
        display: true,
        color: '',
        to: 'reports',
        label: 'Báo cáo',
        objectType: '',
        overviewType: '',
      },
      {
        display: true,
        color: '',
        to: 'resources',
        label: 'Nguồn khách hàng',
        objectType: '',
        overviewType: '',
      },
    ],
  },
  {
    display: true,
    color: '',
    to: '',
    label: 'Cộng tác viên',
    icon: 'ic_partner',
    objectType: '',
    overviewType: '',
    isSubMenu: true,
    subMenu: [
      {
        display: true,
        color: '',
        to: 'partners',
        label: 'Bảng xếp hạng CTV',
        objectType: '',
        overviewType: '',
      },
      {
        display: true,
        color: '',
        to: 'histories',
        label: 'Lịch sử thanh toán',
        objectType: '',
        overviewType: '',
      },
    ],
  },
  // {
  //   display: true,
  //   color: '',
  //   to: '',
  //   label: 'Mã Voucher',
  //   icon: 'ic_voucher',
  //   objectType: '',
  //   overviewType: '',
  // },
  {
    display: true,
    color: '',
    to: '',
    label: 'Khám phá',
    icon: 'ic_explore',
    objectType: '',
    overviewType: '',
    isSubMenu: true,
    subMenu: [
      {
        display: true,
        color: '',
        to: 'challenges',
        label: 'Chinh phục thử thách',
        objectType: '',
        overviewType: '',
      },
      {
        display: true,
        color: '',
        to: 'news',
        label: 'Thử làm điều mới',
        objectType: '',
        overviewType: '',
      },
      {
        display: true,
        color: '',
        to: 'courses',
        label: 'Khóa học giá trị',
        objectType: '',
        overviewType: '',
      },
      {
        display: true,
        color: '',
        to: 'products',
        label: 'Sản phẩm chất lượng',
        objectType: '',
        overviewType: '',
      },
    ],
  },
  {
    display: true,
    color: '',
    to: '',
    label: 'Quảng cáo',
    icon: 'ic_advertisement',
    objectType: '',
    overviewType: '',
    isSubMenu: true,
    subMenu: [
      {
        display: true,
        color: '',
        to: 'advertisements',
        label: 'Quảng cáo',
        objectType: '',
        overviewType: '',
      },
      {
        display: true,
        color: '',
        to: 'notifications',
        label: 'Thông báo',
        objectType: '',
        overviewType: '',
      },
    ],
  },
  {
    display: true,
    color: '',
    to: '',
    label: 'Người dùng',
    icon: 'ic_user',
    objectType: '',
    overviewType: '',
    isSubMenu: true,
    subMenu: [
      {
        display: true,
        color: '',
        to: 'units',
        label: 'Phòng ban',
        objectType: '',
        overviewType: '',
      },
      {
        display: true,
        color: '',
        to: 'admins',
        label: 'Người dùng',
        objectType: '',
        overviewType: '',
      },
      {
        display: true,
        color: '',
        to: 'permissions',
        label: 'Phân quyền',
        objectType: '',
        overviewType: '',
      },
    ],
  },
  // {
  //   display: true,
  //   color: '',
  //   to: '',
  //   label: 'Cs khách hàng',
  //   icon: 'ic_care',
  //   objectType: '',
  //   overviewType: '',
  // },
];
const customMenu = menu;

const LeftMenu = () => {
  const [currentMenu] = useState<null>(null);
  const [itemShow, setItemShow] = useState<number | null>(null);

  const toggleSubMenu = (index: number) => {
    if (index === itemShow) return setItemShow(null);
    setItemShow(index);
  };

  const { isToggle } = useToggleMenu();

  return (
    <div className={cx(css.menu, { [css.menuToggle]: isToggle })}>
      <div className={css.item}>
        <Link to="/" className={css.itemLi}>
          <div className={css.itemLeft}>
            <img src={logo} />
            {!isToggle && <span className={css.logoName}>GOTINO</span>}
          </div>
        </Link>
      </div>

      {customMenu.map((item, index) => {
        return (
          <div
            className={`${css.item} ${currentMenu === item.to ? css.active : ''}`}
            key={`${index}-${item.to}`}>
            {item.isSubMenu ? (
              <a className={css.itemLi} onClick={() => toggleSubMenu(index)}>
                <div className={css.itemLeft}>
                  <div className={`${css.icon} ${css[item.icon]}`} />
                  {!isToggle && <div className={css.name}>{item.label}</div>}
                </div>
                {item.isSubMenu && !isToggle && (
                  <img
                    src={arrowUp}
                    style={index === itemShow ? { transform: 'rotate(180deg)' } : {}}
                    width={14}
                    height={14}
                    onClick={() => toggleSubMenu(index)}
                  />
                )}
              </a>
            ) : (
              <Link to={`/${item.to}`} className={css.itemLi} onClick={() => toggleSubMenu(index)}>
                <div className={css.itemLeft}>
                  <div className={`${css.icon} ${css[item.icon]}`} />
                  {!isToggle && <div className={css.name}>{item.label}</div>}
                </div>
              </Link>
            )}
            {item.isSubMenu && itemShow === index && !isToggle && (
              <div className={css.subMenu}>
                {(item?.subMenu || []).map((subItem, subIndex) => {
                  return (
                    <Link
                      to={`/${subItem.to}`}
                      className={css.itemLi}
                      key={`${index}-${subIndex}-${subItem.to}`}>
                      <div className={css.subName}>{subItem.label}</div>
                    </Link>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default LeftMenu;
