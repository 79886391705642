export const ROUTERS = {
  USERS: 'users',
  CHALLENGES: 'challenges',
  NEWS: 'news',
  COURSES: 'courses',
  PRODUCTS: 'products',
  UNITS: 'units',
  PERMISSIONS: 'permissions',
  ADMINS: 'admins',
  ADVERTISEMENTS: 'advertisements',
  NOTIFICATIONS: 'notifications',
  RESOURCES: 'resources',
  PARTNERS: 'partners',
  HISTORIES: 'histories',
  REPORTS: 'reports',
};
export const PAGE_SIZE = 10;

export interface IPaginationParams {
  take: number;
  skip: number;
}

export interface IListResponse<T> {
  data: {
    total: number;
    items: T[];
  };
}
