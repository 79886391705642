import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { RootState } from '../../store/store';
import { queryParams } from '../../utils/query-string';
import { IListResponse } from '../../configs/constants';
import { IParams, IAds } from './ads';
import { convertModelToFormData } from '../../utils/utility';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 2 });

export const adsApi = createApi({
  reducerPath: 'adsApi',
  baseQuery: baseQueryWithRetry,
  tagTypes: ['Ads'],
  endpoints: (builder) => ({
    getAds: builder.query<IListResponse<IAds>, { page: number; pageSize: number; status?: string }>(
      {
        query: ({ page, pageSize, status }) => {
          const params: IParams = {
            take: pageSize,
            skip: (page - 1) * pageSize,
          };
          if (status) params.status = status;
          return {
            url: `advertisements?${queryParams(params)}`,
            method: 'GET',
          };
        },
        providesTags: ['Ads'],
      },
    ),
    addAds: builder.mutation<{ data: IAds; type: string }, any>({
      query: (body) => {
        return {
          url: 'advertisements',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Ads'],
    }),
    updateAds: builder.mutation<{ data: IAds; type: string }, any>({
      query: (body) => {
        const { id } = body;
        delete body.id;
        return {
          url: `advertisements/${id}`,
          method: 'PATCH',
          body: convertModelToFormData(body),
        };
      },
      invalidatesTags: ['Ads'],
    }),
    deleteAds: builder.mutation<{ data: string; type: string }, any>({
      query: (id) => {
        return {
          url: `advertisements/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Ads'],
    }),
  }),
});

export const { useAddAdsMutation, useDeleteAdsMutation, useUpdateAdsMutation, useGetAdsQuery } =
  adsApi;
