import SignInForm from '../SignForm/index';
import { Container, FormContainer, Logo, MainForm } from './styles.css';
import logo from './logo.png';

const SignIn = (): JSX.Element => {
  return (
    <Container>
      <FormContainer>
        <Logo>
          <img src={logo} alt="Gotino" style={{ height: '30px', width: '30px' }} />
        </Logo>
        <MainForm>
          <SignInForm />
        </MainForm>
      </FormContainer>
    </Container>
  );
};
export default SignIn;
