import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ReportsProvider } from '../../modules/Reports/Context/reports.context';
import { ROUTERS } from '../../configs/constants';

const Users = lazy(() => import('../../modules/Users'));
const Challenges = lazy(() => import('../../modules/Challenges'));
const News = lazy(() => import('../../modules/News'));
const Courses = lazy(() => import('../../modules/Courses'));
const Products = lazy(() => import('../../modules/Products'));
const Units = lazy(() => import('../../modules/Units'));
const Permissions = lazy(() => import('../../modules/Permissions'));
const Admins = lazy(() => import('../../modules/Admins'));
const Ads = lazy(() => import('../../modules/Advertisements'));
const Notifications = lazy(() => import('../../modules/Notifications'));
const Resources = lazy(() => import('../../modules/Resources'));
const Partners = lazy(() => import('../../modules/Partners'));
const Histories = lazy(() => import('../../modules/Histories'));
const Reports = lazy(() => import('../../modules/Reports'));

const AppBody = () => {
  return (
    <Routes>
      <Route
        element={
          <React.Suspense fallback={<>...</>}>
            <Users />
          </React.Suspense>
        }
        path={`/${ROUTERS.USERS}`}
      />
      <Route
        element={
          <React.Suspense fallback={<>...</>}>
            <Challenges />
          </React.Suspense>
        }
        path={`/${ROUTERS.CHALLENGES}`}
      />
      <Route
        element={
          <React.Suspense fallback={<>...</>}>
            <News />
          </React.Suspense>
        }
        path={`/${ROUTERS.NEWS}`}
      />
      <Route
        element={
          <React.Suspense fallback={<>...</>}>
            <Courses />
          </React.Suspense>
        }
        path={`/${ROUTERS.COURSES}`}
      />
      <Route
        element={
          <React.Suspense fallback={<>...</>}>
            <Products />
          </React.Suspense>
        }
        path={`/${ROUTERS.PRODUCTS}`}
      />
      <Route
        element={
          <React.Suspense fallback={<>...</>}>
            <Units />
          </React.Suspense>
        }
        path={`/${ROUTERS.UNITS}`}
      />
      <Route
        element={
          <React.Suspense fallback={<>...</>}>
            <Permissions />
          </React.Suspense>
        }
        path={`/${ROUTERS.PERMISSIONS}`}
      />
      <Route
        element={
          <React.Suspense fallback={<>...</>}>
            <Admins />
          </React.Suspense>
        }
        path={`/${ROUTERS.ADMINS}`}
      />
      <Route
        element={
          <React.Suspense fallback={<>...</>}>
            <Ads />
          </React.Suspense>
        }
        path={`/${ROUTERS.ADVERTISEMENTS}`}
      />
      <Route
        element={
          <React.Suspense fallback={<>...</>}>
            <Notifications />
          </React.Suspense>
        }
        path={`/${ROUTERS.NOTIFICATIONS}`}
      />
      <Route
        element={
          <React.Suspense fallback={<>...</>}>
            <Resources />
          </React.Suspense>
        }
        path={`/${ROUTERS.RESOURCES}`}
      />
      <Route
        element={
          <React.Suspense fallback={<>...</>}>
            <Partners />
          </React.Suspense>
        }
        path={`/${ROUTERS.PARTNERS}`}
      />
      <Route
        path={`/${ROUTERS.HISTORIES}`}
        element={
          <React.Suspense fallback={<>...</>}>
            <Histories />
          </React.Suspense>
        }
      />
      <Route
        path={`/${ROUTERS.REPORTS}`}
        element={
          <React.Suspense fallback={<>...</>}>
            <ReportsProvider>
              <Reports />
            </ReportsProvider>
          </React.Suspense>
        }
      />
      <Route
        path="*"
        element={
          <React.Suspense fallback={<>...</>}>
            <Users />
          </React.Suspense>
        }
      />
    </Routes>
  );
};
export default AppBody;
