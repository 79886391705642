import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { RootState } from '../../store/store';
import { queryParams } from '../../utils/query-string';
import { IListResponse } from '../../configs/constants';
import { IParams, IUnits } from './units';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 2 });

export const unitApi = createApi({
  reducerPath: 'unitApi',
  baseQuery: baseQueryWithRetry,
  tagTypes: ['Unit'],
  endpoints: (builder) => ({
    getUnits: builder.query<
      IListResponse<IUnits>,
      { page: number; pageSize: number; keyword?: string }
    >({
      query: ({ page, pageSize, keyword }) => {
        const params: IParams = {
          take: pageSize,
          skip: (page - 1) * pageSize,
        };
        if (keyword) params.keyword = keyword;
        return {
          url: `units?${queryParams(params)}`,
          method: 'GET',
        };
      },
      providesTags: ['Unit'],
    }),
    addUnit: builder.mutation<{ data: IUnits; type: string }, any>({
      query: (body) => {
        return {
          url: 'units',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Unit'],
    }),
    updateUnit: builder.mutation<{ data: IUnits; type: string }, any>({
      query: (body) => {
        const { id } = body;
        delete body.id;
        return {
          url: `units/${id}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['Unit'],
    }),
    deleteUnit: builder.mutation<{ data: string; type: string }, any>({
      query: (id) => {
        return {
          url: `units/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Unit'],
    }),
  }),
});

export const {
  useGetUnitsQuery,
  useAddUnitMutation,
  useUpdateUnitMutation,
  useDeleteUnitMutation,
} = unitApi;
