import { Popup, Flag } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import css from './Language.module.scss';
import icArrow from '../icons/ic_arrow.svg';

const popupStyle = {
  padding: '0px 0 0 0',
  maxWidth: 150,
  minWidth: 150,
  borderRadius: '2px !important',
};

const Language = () => {
  const { t, i18n } = useTranslation();

  const languageItem = (
    <div className={css.container}>
      <Flag name={i18n.language as any} />
      <img src={icArrow} width={6} height={6} />
    </div>
  );
  return (
    <Popup
      flowing
      basic
      hoverable
      position="bottom right"
      size="huge"
      style={popupStyle}
      trigger={languageItem}>
      <div className={css.menu}>
        <div className={css.item} onClick={() => i18n.changeLanguage('vn')}>
          <Flag name="vn" />
          <span>{t('Vietnam')}</span>
        </div>
        <div className={css.item} onClick={() => i18n.changeLanguage('us')}>
          <Flag name="us" />
          <span>{t('United States')}</span>
        </div>
      </div>
    </Popup>
  );
};
export default Language;
