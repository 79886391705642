/* eslint-disable no-plusplus */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */

export const convertModelToFormData = (val: any, formData = new FormData(), namespace = '') => {
  if (typeof val !== 'undefined' && val !== null) {
    if (val instanceof Date) {
      formData.append(namespace, val.toISOString());
    } else if (val instanceof Array) {
      for (let i = 0; i < val.length; i++) {
        convertModelToFormData(val[i], formData, `${namespace}[${i}]`);
      }
    } else if (typeof val === 'object' && !(val instanceof File)) {
      for (const propertyName in val) {
        if (val.hasOwnProperty(propertyName)) {
          convertModelToFormData(
            val[propertyName],
            formData,
            namespace ? `${namespace}[${propertyName}]` : propertyName,
          );
        }
      }
    } else if (val instanceof File) {
      formData.append(namespace, val);
    } else {
      formData.append(namespace, val.toString());
    }
  }
  return formData;
};
