import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { IUser } from './auth.type';
import { RootState } from '../../store/store';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set('authentication', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 2 });

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQueryWithRetry,
  tagTypes: ['Auth'],
  endpoints: (build) => ({
    login: build.mutation<
      { data: { user: IUser; token: string } },
      { email: string; password: string }
    >({
      query: (body) => ({ url: 'auth/login', method: 'POST', body }),
    }),
  }),
});

export const {
  endpoints: { login },
} = authApi;

export const { useLoginMutation } = authApi;
