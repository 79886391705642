import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { RootState } from '../../store/store';
import { queryParams } from '../../utils/query-string';
import { IListResponse } from '../../configs/constants';
import { IParams, IPartner } from './partners';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 2 });

export const partnerApi = createApi({
  reducerPath: 'partnerApi',
  baseQuery: baseQueryWithRetry,
  tagTypes: ['Partner'],
  endpoints: (builder) => ({
    getPartners: builder.query<
      IListResponse<IPartner>,
      { page: number; pageSize: number; keyword?: string }
    >({
      query: ({ page, pageSize, keyword }) => {
        const params: IParams = {
          take: pageSize,
          skip: (page - 1) * pageSize,
        };
        if (keyword) params.keyword = keyword;
        return {
          url: `users/partners?${queryParams(params)}`,
          method: 'GET',
        };
      },
      providesTags: ['Partner'],
    }),
    getPartner: builder.query<any, { id: string }>({
      query: ({ id }) => {
        return {
          url: `users/partners/${id}`,
          method: 'GET',
        };
      },
      providesTags: ['Partner'],
    }),
    getTotalBonus: builder.query<any, { id: string; month: number; year: number }>({
      query: ({ id, month, year }) => {
        return {
          url: `users/partners/${id}/getTotalBonus?month=${month}&year=${year}`,
          method: 'GET',
        };
      },
      providesTags: ['Partner'],
    }),
    addPayment: builder.mutation<
      { data: { id: string; month: number; year: number; amount: number }; type: string },
      any
    >({
      query: ({ id, month, year, amount }) => {
        return {
          url: `users/partners/${id}/payment`,
          method: 'POST',
          body: { month, year, amount },
        };
      },
      invalidatesTags: ['Partner'],
    }),
  }),
});

export const {
  useGetPartnersQuery,
  useGetPartnerQuery,
  useGetTotalBonusQuery,
  useAddPaymentMutation,
} = partnerApi;
