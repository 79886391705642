import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { RootState } from '../../store/store';
import { queryParams } from '../../utils/query-string';
import { IListResponse } from '../../configs/constants';
import { IParams, IAdmin } from './admins';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 2 });

export const adminApi = createApi({
  reducerPath: 'adminApi',
  baseQuery: baseQueryWithRetry,
  tagTypes: ['Admin'],
  endpoints: (builder) => ({
    getAdmins: builder.query<
      IListResponse<IAdmin>,
      { page: number; pageSize: number; status?: string; keyword?: string; unitId?: string }
    >({
      query: ({ page, pageSize, status, keyword, unitId }) => {
        const params: IParams = {
          take: pageSize,
          skip: (page - 1) * pageSize,
        };
        if (status) params.status = status;
        if (keyword) params.keyword = keyword;
        if (unitId) params.unitId = unitId;
        return {
          url: `admins?${queryParams(params)}`,
          method: 'GET',
        };
      },
      providesTags: ['Admin'],
    }),
    addAdmin: builder.mutation<{ data: IAdmin; type: string }, any>({
      query: (body) => {
        return {
          url: 'admins',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Admin'],
    }),
    updateAdmin: builder.mutation<{ data: IAdmin; type: string }, any>({
      query: (body) => {
        const { id } = body;
        delete body.id;
        return {
          url: `admins/${id}`,
          method: 'PATCH',
          body: body,
        };
      },
      invalidatesTags: ['Admin'],
    }),
    deleteAdmin: builder.mutation<{ data: string; type: string }, any>({
      query: (id) => {
        return {
          url: `admins/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Admin'],
    }),
  }),
});

export const {
  useGetAdminsQuery,
  useAddAdminMutation,
  useUpdateAdminMutation,
  useDeleteAdminMutation,
} = adminApi;
