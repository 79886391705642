import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { RootState } from '../../store/store';
import { queryParams } from '../../utils/query-string';
import { IListResponse } from '../../configs/constants';
import { IParams, INotifications } from './notifications';
import { convertModelToFormData } from '../../utils/utility';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 2 });

export const notificationApi = createApi({
  reducerPath: 'notificationApi',
  baseQuery: baseQueryWithRetry,
  tagTypes: ['Notification'],
  endpoints: (builder) => ({
    getNotifications: builder.query<
      IListResponse<INotifications>,
      { page: number; pageSize: number; isSend?: boolean | string }
    >({
      query: ({ page, pageSize, isSend }) => {
        const params: IParams = {
          take: pageSize,
          skip: (page - 1) * pageSize,
        };
        if (isSend) params.isSend = isSend;
        return {
          url: `notifications?${queryParams(params)}`,
          method: 'GET',
        };
      },
      providesTags: ['Notification'],
    }),
    addNotifications: builder.mutation<{ data: INotifications; type: string }, any>({
      query: (body) => {
        return {
          url: 'notifications',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Notification'],
    }),
    updateNotification: builder.mutation<{ data: INotifications; type: string }, any>({
      query: (body) => {
        const { id } = body;
        delete body.id;
        return {
          url: `notifications/${id}`,
          method: 'PATCH',
          body: convertModelToFormData(body),
        };
      },
      invalidatesTags: ['Notification'],
    }),
    deleteNotification: builder.mutation<{ data: string; type: string }, any>({
      query: (id) => {
        return {
          url: `notifications/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Notification'],
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  useAddNotificationsMutation,
  useUpdateNotificationMutation,
  useDeleteNotificationMutation,
} = notificationApi;
