import { Route, Routes, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SignIn from './modules/Auth/SignIn';
import LeftMenu from './components/LeftMenu';
import AppBar from './components/AppBar';
import AppBody from './components/App/appBody';
import { selectIsAuthenticated } from './modules/Auth/auth.slice';
import './styles/theme.scss';
import css from './App.module.scss';
import { ToggleMenuProvider } from './hooks/useToggleMenu';

String.prototype.formatNumber = function () {
  const str = this.toString().replace(/\./g, '');
  return str.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
};

const App = (): JSX.Element => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const location = useLocation();
  if (!isAuthenticated) {
    return (
      <>
        <Routes>
          <Route element={<SignIn />} path="/sign-in" />
          <Route element={<SignIn />} path="*" />
        </Routes>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {/* Same as */}
        <ToastContainer />
      </>
    );
  }

  return (
    <div className={css.root}>
      <div className={css.body}>
        <ToggleMenuProvider>
          <div className={css.bsBody}>
            <div className={css.bsLeftMenu}>
              <LeftMenu />
            </div>
            <div className={css.bsContent}>
              <AppBar />
              <div
                className={location.pathname === '/reports' ? css.bsContainerR : css.bsContainer}>
                <AppBody />
              </div>
            </div>
          </div>
        </ToggleMenuProvider>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Same as */}
      <ToastContainer />
    </div>
  );
};

export default App;
