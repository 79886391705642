import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { RootState } from '../../store/store';
import { queryParams } from '../../utils/query-string';
import { IListResponse } from '../../configs/constants';
import { IParams, IProducts } from './products';
import { convertModelToFormData } from '../../utils/utility';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 2 });

export const productApi = createApi({
  reducerPath: 'productApi',
  baseQuery: baseQueryWithRetry,
  tagTypes: ['Product'],
  endpoints: (builder) => ({
    getProducts: builder.query<
      IListResponse<IProducts>,
      { page: number; pageSize: number; status?: string }
    >({
      query: ({ page, pageSize, status }) => {
        const params: IParams = {
          take: pageSize,
          skip: (page - 1) * pageSize,
        };
        if (status) params.status = status;
        return {
          url: `products?${queryParams(params)}`,
          method: 'GET',
        };
      },
      providesTags: ['Product'],
    }),
    addProduct: builder.mutation<{ data: IProducts; type: string }, any>({
      query: (body) => {
        return {
          url: 'products',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Product'],
    }),
    updateProduct: builder.mutation<{ data: IProducts; type: string }, any>({
      query: (body) => {
        const { id } = body;
        delete body.id;
        return {
          url: `products/${id}`,
          method: 'PATCH',
          body: convertModelToFormData(body),
        };
      },
      invalidatesTags: ['Product'],
    }),
    deleteProduct: builder.mutation<{ data: string; type: string }, any>({
      query: (id) => {
        return {
          url: `products/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Product'],
    }),
  }),
});

export const {
  useGetProductsQuery,
  useAddProductMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
} = productApi;
