import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { pickBy, identity } from 'lodash';
import { RootState } from '../../store/store';
import { IListResponse } from '../../configs/constants';
import { queryParams } from '../../utils/query-string';
import { IParams, IUser } from './users';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 2 });

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: baseQueryWithRetry,
  tagTypes: ['User'],
  endpoints: (builder) => ({
    getUsers: builder.query<
      IListResponse<IUser>,
      { page: number; pageSize: number; keyword?: string; status?: string; packageType?: string }
    >({
      query: ({ page, pageSize, keyword, status, packageType }) => {
        const params: IParams = {
          take: pageSize,
          skip: (page - 1) * pageSize,
        };
        if (keyword) params.keyword = keyword;
        if (status) params.status = status;
        if (packageType) params.packageType = packageType;
        return {
          url: `users/list?${queryParams(params)}`,
          method: 'GET',
        };
      },
      providesTags: ['User'],
    }),
    addUser: builder.mutation<{ data: IUser; type: string }, any>({
      query: (body) => {
        return {
          url: 'users',
          method: 'POST',
          body: { ...pickBy(body, identity) },
        };
      },
      invalidatesTags: ['User'],
    }),
    deleteUser: builder.mutation<{ data: string; type: string }, any>({
      query: (id) => {
        return {
          url: `users/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['User'],
    }),
    updateUser: builder.mutation<{ data: IUser; type: string }, any>({
      query: (body) => {
        const { id } = body;
        delete body.id;
        return {
          url: `users/${id}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['User'],
    }),
    getUser: builder.query<any, { id: string }>({
      query: ({ id }) => {
        return {
          url: `users/${id}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  endpoints: { getUsers },
} = userApi;
export const {
  useGetUsersQuery,
  useAddUserMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useGetUserQuery,
} = userApi;
