import LanguageDropdown from './Language';
import ProfileMenu from './ProfileMenu';
import css from './AppBar.module.scss';
import icToggle from './icons/ic_toggle.svg';
import { useToggleMenu } from '../../hooks/useToggleMenu';

const AppBar = () => {
  const { setToggle, isToggle } = useToggleMenu();
  return (
    <div className={css.container}>
      <div className={css.toggleMenu} onClick={() => setToggle && setToggle(!isToggle)}>
        <img src={icToggle} width={10} height={8} />
      </div>
      <div className={css.rightAppBar}>
        <ProfileMenu />
        <LanguageDropdown />
      </div>
    </div>
  );
};
export default AppBar;
