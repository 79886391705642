import { createContext, FC, useState } from 'react';
import { PAGE_SIZE } from '../../../configs/constants';

type IReportsContext = {
  page: number;
  pageSize: number;
  status: string;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  setStatus: (status: string) => void;
  modal?: { [key: string]: any };
  setModal?: (params: { [key: string]: any }) => void;
  unit: string;
  setUnit: (param: string) => void;
  keyword: string;
  setKeyword: (param: string) => void;
  time: string;
  setTime: (param: string) => void;
};

export const ReportsContext = createContext<IReportsContext>({
  page: 1,
  pageSize: PAGE_SIZE,
  status: '',
  setPage: () => {},
  setPageSize: () => {},
  setStatus: () => {},
  unit: '',
  keyword: '',
  time: 'WEEK',
  setUnit: () => {},
  setKeyword: () => {},
  setTime: () => {},
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps {}
export const ReportsProvider: FC<IProps> = ({ children }) => {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);
  const [status, setStatus] = useState<string>('');
  const [modal, setModal] = useState<{ [key: string]: any }>({});
  const [unit, setUnit] = useState<string>('');
  const [keyword, setKeyword] = useState<string>('');
  const [time, setTime] = useState<string>('WEEK');

  return (
    <ReportsContext.Provider
      value={{
        page,
        pageSize,
        setPage: (key: number) => setPage(key),
        setPageSize: (key: number) => setPageSize(key),
        status,
        setStatus: (key: string) => setStatus(key),
        modal,
        setModal: (params: { [key: string]: any }) => setModal(params),
        unit,
        keyword,
        setUnit: (param: string) => setUnit(param),
        setKeyword: (param: string) => setKeyword(param),
        time,
        setTime: (param: string) => setTime(param),
      }}>
      {children}
    </ReportsContext.Provider>
  );
};
